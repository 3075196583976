<template>
  <AppGrid :class="$style.container" data-analytics-region="footer">
    <BaseLink data-analytics="logo" :class="$style.logo" to="/signin" @click="trackEvent">
      <BaseLogo :class="$style['logo--footer']" />
    </BaseLink>
    <AppGridItem as="nav" role="navigation" aria-label="Footer" :class="$style.nav">
      <!-- <BaseLink light :to="{ name: routeNames.WHY_DAYFORWARD }" data-analytics="why_dayforward" @click="trackEvent">
        Why Dayforward
      </BaseLink>
      <BaseLink light :to="{ name: routeNames.ABOUT }" data-analytics="about" @click="trackEvent">About</BaseLink>
      <BaseLink light :to="{ name: routeNames.FAQ }" data-analytics="faqs" @click="trackEvent">FAQs</BaseLink>
      <BaseLink light :to="{ name: routeNames.REVIEWS }" data-analytics="reviews" @click="trackEvent">
        Reviews
      </BaseLink> -->
      <BaseLink light :to="{ name: routeNames.CAREERS }" data-analytics="careers" @click="trackEvent">Careers</BaseLink>
      <BaseLink light href="//dayforward.io" data-analytics="solutions" @click="trackEvent">
        Business Solutions
      </BaseLink>
      <BaseLink light :to="{ name: routeNames.CONTACT }" data-analytics="contact" @click="trackEvent">
        Contact us
      </BaseLink>
    </AppGridItem>
    <AppGridItem :class="$style.actions">
      <BaseButton :data-analytics="!auth ? 'signin' : 'signout'" variant="reverse" outline @click="handleAuth">
        {{ !auth ? "Sign in" : "Log out" }}
      </BaseButton>
    </AppGridItem>
    <AppGridItem :class="$style.contact">
      <BaseLink :href="`tel:${Contact.phone}`" light>{{ Contact.phone }}</BaseLink>
      <br />
      <BaseText as="span" size="sm">M-F: {{ Contact.hours.weekdays }}</BaseText>
      <br />
      <BaseText as="span" size="sm">Weekends: {{ Contact.hours.weekends }}</BaseText>
    </AppGridItem>
    <AppGridItem :class="$style.links">
      <AppGridItem as="nav" role="navigation" aria-label="Social" :class="$style.social">
        <BaseLink
          light
          href="https://linkedin.com/company/dayfwrd"
          data-analytics="linkedin"
          target="_blank"
          aria-label="LinkedIn"
          rel="noopener noreferrer"
          :class="$style['social__link']"
          @click="trackEvent"
        >
          <!-- eslint-disable-next-line prettier/prettier -->
          <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="currentColor">
            <path
              d="M18.3362 18.339H15.6707V14.1622C15.6707 13.1662 15.6505 11.8845 14.2817 11.8845C12.892 11.8845 12.6797 12.9683 12.6797 14.0887V18.339H10.0142V9.75H12.5747V10.9207H12.6092C12.967 10.2457 13.837 9.53325 15.1367 9.53325C17.8375 9.53325 18.337 11.3108 18.337 13.6245V18.339H18.3362ZM7.00373 8.57475C6.14573 8.57475 5.45648 7.88025 5.45648 7.026C5.45648 6.1725 6.14648 5.47875 7.00373 5.47875C7.85873 5.47875 8.55173 6.1725 8.55173 7.026C8.55173 7.88025 7.85798 8.57475 7.00373 8.57475ZM8.34023 18.339H5.66723V9.75H8.34023V18.339ZM19.6697 3H4.32923C3.59498 3 3.00098 3.5805 3.00098 4.29675V19.7033C3.00098 20.4202 3.59498 21 4.32923 21H19.6675C20.401 21 21.001 20.4202 21.001 19.7033V4.29675C21.001 3.5805 20.401 3 19.6675 3H19.6697Z"
            ></path>
          </svg>
          <BaseText as="span" size="sm">LinkedIn</BaseText>
        </BaseLink>
      </AppGridItem>
      <AppGridItem>
        <!-- eslint-disable-next-line prettier/prettier -->
        <a
          href="https://www.bbb.org/us/ny/new-york/profile/life-insurance/dayforward-0121-87169675/#sealclick"
          target="_blank"
          rel="nofollow"
          :class="$style.bbb"
          ><img
            src="https://seal-newyork.bbb.org/seals/darkgray-seal-200-42-whitetxt-bbb-87169675.png"
            style="border: 0"
            alt="Dayforward BBB Business Review"
        /></a>
      </AppGridItem>
    </AppGridItem>
    <AppGridItem as="nav" role="navigation" aria-label="Legal" :class="$style['nav--legal']">
      <BaseLink light size="sm" data-analytics="terms" :to="{ name: routeNames.TERMS }" @click="trackEvent">
        Terms of use
      </BaseLink>
      <BaseLink light size="sm" data-analytics="privacy" :to="{ name: routeNames.PRIVACY }" @click="trackEvent">
        Privacy policy
      </BaseLink>
    </AppGridItem>
    <AppGridItem :class="$style.legal">
      <BaseText size="sm">
        {{ legal }}
        <BaseLink light underline :to="{ name: routeNames.LICENSES }">
          {{ legal === Footnotes.legal.dlic ? "following states" : "See all state licenses" }}.
        </BaseLink>
      </BaseText>
    </AppGridItem>
    <BaseText size="sm" :class="$style.trademark">
      &copy; {{ year }} Dayforward Inc. All Rights Reserved. Dayforward is a trademark of Dayforward Inc.
    </BaseText>
  </AppGrid>
</template>

<script setup>
import { useAnalytics } from "@common";
import BaseButton from "@common/components/BaseButton.vue";
import BaseLink from "@common/components/BaseLink.vue";
import BaseLogo from "@common/components/BaseLogo.vue";
import BaseText from "@common/components/BaseText.vue";
import AppGrid from "@components/AppGrid.vue";
import AppGridItem from "@components/AppGridItem.vue";
import { useSessionStorage } from "@common/composables";
import { STORAGE_KEYS, useContent, useDescope, useExperiment, useUser } from "@composables";
import { computed, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";

import { routeNames } from "../constants";
import Contact from "../data/contact.json";
import Footnotes from "../data/footnotes.json";
import { useAuthStore } from "../store/auth.store";

// Authenticated
const authStore = useAuthStore();
const auth = computed(() => authStore.authenticated);
// END

// Footer dynamic text
const { interview } = useUser();
const { setFooter, legal, updateFooter } = useContent();
// hasFeature returns undefined before timout
watchEffect(
  () => {
    if (!interview.value) setFooter(Footnotes.legal.default);
    if (interview.value) updateFooter(null, interview.value);
  },
  {
    flush: "post",
  },
);
// END

// Analytics
const analytics = useAnalytics();
const route = useRoute();
const router = useRouter();
const { bucket } = useExperiment();
function trackEvent(e) {
  // Track footer navigation clicks
  const target = e.target.closest("a") || e.target.closest("button");
  const event = target.getAttribute("data-analytics");
  const current = router.options.history.state.current;
  const page = current === "/" ? "homepage" : current.substring(1);

  if (event) {
    const key = `app_click_${event}`;
    analytics.trackEvent(key, {
      button_location: "footer",
      page,
      region: "footer",
      url: route.path,
      experiment: bucket.value,
    });
  }
}

const { descope } = useDescope();
const { remove } = useSessionStorage();
const handleAuth = async (e) => {
  trackEvent(e);
  if (authStore.authenticated) {
    await descope.client.logout();
    authStore.setState("$authenticated", false);
    remove(STORAGE_KEYS.DF_LEAD);
    remove(STORAGE_KEYS.DF_ESTIMATE);
  }
  if (["interview", "overview"].includes(route.name) || !authStore.authenticated)
    router.replace({ name: routeNames.AUTH_SIGN_IN });
};

// Footer trademark
const year = new Date().getFullYear();
</script>

<style lang="scss" module>
// Again with the a tag override with !important because of _text.scss line 3
.logo {
  border-bottom: 0 !important;
  &:hover {
    opacity: 0.8;
    border-bottom: 0 !important;
  }
}

.logo--footer {
  fill: var(--oat);
}

.container {
  align-items: flex-start;
  width: 90%;
  max-width: 1254px;
  padding: 5.5rem 0 7.5rem;
  margin-right: auto;
  margin-left: auto;
}

.nav,
.actions,
.contact,
.links,
.nav--legal,
.legal,
.trademark {
  grid-column-start: 1;
  grid-column-end: 3;
}

.nav {
  display: grid;
  gap: 0.75rem;
  grid-row: 2;
  align-items: flex-start;
}

.actions {
  grid-row: 3;
}

.contact {
  grid-row: 4;
}

.links {
  grid-row: 5;
}

.social {
  display: flex;
  gap: 0.75rem;
}

.social__link {
  display: inline-flex;
  gap: 4px;
  align-items: center;
}

.bbb {
  display: inline-block;
  margin-top: 12px;
}

.nav--legal {
  display: flex;
  gap: 1.5rem;
  grid-row: 6;
  align-items: flex-start;
}

.legal {
  grid-row: 7;
}

.trademark {
  grid-row: 8;
}

@media (min-width: 600px) {
  // .nav--legal {
  //   grid-column: span 1 / -1;
  // }
  .nav,
  .actions,
  .links,
  .nav--legal,
  .legal,
  .trademark {
    grid-column-end: 5;
  }

  .contact {
    grid-column-end: 3;
  }
}

@media (min-width: 768px) {
  .nav,
  .nav--legal,
  .legal,
  .trademark {
    grid-column-end: 7;
  }
  .nav {
    grid-template-columns: 1fr 1fr;
    grid-row: 1;
    grid-column-start: 3;
  }

  .actions {
    grid-row: 2;
    grid-column-start: 3;
    grid-column-end: -1;
  }

  .contact {
    grid-row: 2;
  }

  .links {
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .nav--legal {
    grid-row: 5;
    grid-column-start: 1;
  }

  .legal {
    grid-row: 6;
  }

  .trademark {
    grid-row: 7;
  }
}

@media (min-width: 992px) {
  .nav {
    grid-template-columns: 1fr 1fr;
    grid-column-start: 3;
    grid-column-end: 7;
    gap: 0.75rem 0;
  }

  .actions {
    grid-row: 1;
    grid-column-start: 7;
    grid-column-end: 9;
  }

  .nav--legal {
    grid-row: 4;
    grid-column-start: 3;
    align-items: flex-start;
  }

  .links {
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .social {
    display: flex;
    gap: 0.75rem;
  }

  .legal {
    grid-row: 4;
    grid-column-start: 3;
    grid-column-end: 9;
    margin-top: 32px;
  }
  .trademark {
    grid-row: 5;
    grid-column-start: 3;
    grid-column-end: 9;
  }
}

@media (min-width: 1124px) {
  .nav {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  // .actions {
  //   grid-row: 1;
  //   grid-column-start: 7;
  //   grid-column-end: 9;
  // }

  .nav--legal {
    grid-column-start: 4;
  }

  .legal {
    grid-column-start: 4;
  }
  .trademark {
    grid-column-start: 4;
  }
}

@media (min-width: 1200px) {
  .nav {
    grid-column-end: 8;
  }

  .actions,
  .legal,
  .trademark {
    grid-column-end: -1;
  }
  .actions {
    grid-column-start: 11;
  }
}
</style>
